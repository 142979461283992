var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "my-1",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "text": "",
      "color": "orange",
      "ripple": false,
      "dark": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.dialog = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("generalTermsAndConditionsDialogTitle")) + " ")]), _c('v-dialog', {
    attrs: {
      "max-width": "800",
      "primary-title": ""
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t("generalTermsAndConditionsDialogTitle")) + " ")]), _c('v-divider', {
    staticClass: "mt-1"
  }), _c('div', {
    staticClass: "ma-4 d-md-flex justify-space-around"
  }, [_c('v-card', {
    attrs: {
      "elevation": "5",
      "max-width": "400"
    }
  }, [_c('v-card-title', {
    staticClass: "sizeTitle"
  }, [_vm._v(" " + _vm._s(_vm.$t("generalTermsAndConditionsUse")) + " ")]), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "orange text-none",
    attrs: {
      "target": "_blank",
      "dark": "",
      "rel": "noopener noreferrer",
      "href": "/contracts/cgu.pdf"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-external-link-alt ")]), _vm._v(" " + _vm._s(_vm.$t('Open')) + " ")], 1)], 1)], 1), _c('v-card', {
    attrs: {
      "elevation": "5",
      "max-width": "400"
    }
  }, [_c('v-card-title', {
    staticClass: "sizeTitle"
  }, [_vm._v(" " + _vm._s(_vm.$t("generalTermsAndConditionsSale")) + " ")]), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "orange text-none",
    attrs: {
      "target": "_blank",
      "dark": "",
      "rel": "noopener noreferrer",
      "href": "/contracts/cgv.pdf"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-external-link-alt ")]), _vm._v(" " + _vm._s(_vm.$t('Open')) + " ")], 1)], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t("Close")) + " ")], 1)], 1)], 1)], 1), _vm.updateCGV || _vm.updateCGU ? _c('v-dialog', {
    attrs: {
      "max-width": "800",
      "primary-title": "",
      "persistent": ""
    },
    model: {
      value: _vm.dialogUpdate,
      callback: function callback($$v) {
        _vm.dialogUpdate = $$v;
      },
      expression: "dialogUpdate"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t("generalTermsAndConditionsDialogTitle")) + " ")]), _c('v-divider', {
    staticClass: "mt-1"
  }), _c('div', {
    staticClass: "ma-4 d-md-flex justify-space-around"
  }, [_c('v-card', {
    attrs: {
      "elevation": "5",
      "max-width": "400"
    }
  }, [_c('v-card-title', {
    staticClass: "sizeTitle"
  }, [_vm._v(" " + _vm._s(_vm.$t("generalTermsAndConditionsUse")) + " ")]), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "orange text-none",
    attrs: {
      "target": "_blank",
      "dark": "",
      "rel": "noopener noreferrer",
      "href": "/contracts/cgu.pdf"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-external-link-alt ")]), _vm._v(" " + _vm._s(_vm.$t('Open')) + " ")], 1)], 1)], 1), _c('v-card', {
    attrs: {
      "elevation": "5",
      "max-width": "400"
    }
  }, [_c('v-card-title', {
    staticClass: "sizeTitle"
  }, [_vm._v(" " + _vm._s(_vm.$t("generalTermsAndConditionsSale")) + " ")]), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "orange text-none",
    attrs: {
      "target": "_blank",
      "dark": "",
      "rel": "noopener noreferrer",
      "href": "/contracts/cgv.pdf"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-external-link-alt ")]), _vm._v(" " + _vm._s(_vm.$t('Open')) + " ")], 1)], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card-actions', {
    staticClass: "d-block"
  }, [_vm.updateCGU ? _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('AcceptCGU', {
        dateContractCGU: _vm.dateContractCGU
      })
    },
    on: {
      "change": _vm.checkChange
    },
    model: {
      value: _vm.selectedCGU,
      callback: function callback($$v) {
        _vm.selectedCGU = $$v;
      },
      expression: "selectedCGU"
    }
  })], 1) : _vm._e(), _vm.updateCGV ? _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('AcceptCGV', {
        dateContractCGV: _vm.dateContractCGV
      })
    },
    on: {
      "change": _vm.checkChange
    },
    model: {
      value: _vm.selectedCGV,
      callback: function callback($$v) {
        _vm.selectedCGV = $$v;
      },
      expression: "selectedCGV"
    }
  })], 1) : _vm._e()]), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card-actions', {
    staticClass: "d-flex justify-center"
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error",
      "to": {
        name: 'Logout'
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t("Decline")) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "disabled": !_vm.validContracts
    },
    on: {
      "click": _vm.validationContracts
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t("Validate")) + " ")], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }