<template lang="html" src="./supportEmail.template.vue"></template>

<style lang="scss" src="./supportEmail.scss"></style>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./supportEmail.i18n');

export default {
  name: 'SupportEmail',
  props: {
    preSubject: {
      type: String,
      default: '',
    },
    displayButton: {
      type: Boolean,
      default: true,
    },
    subject: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    }
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      bodyMessage: '',
      subjectMessage: '',
      dialogSupportEmail: false,
      loading: false,
      subjectRules: [(value) => !!value || this.$t('ProvideSubject')],
      messageRules: [(value) => !!value || this.$t('ProvideMessage')],
    };
  },
  created() {
    this.bodyMessage = this.body;
    this.subjectMessage = this.subject;
  },
  methods: {
    sendTicket() {
      if (this.subjectMessage.trim() === '') {
        this.subjectMessage = '';
      }
      if (this.bodyMessage.trim() === '') {
        this.bodyMessage = '';
      }
      if (this.$refs.supportEmailForm.validate()) {
        this.loading = true;
        const sendSupportEmailTicketBody = new this.$BcmModel.SendSupportEmailTicketBody(
          `${this.preSubject} ${this.subjectMessage}`,
          this.bodyMessage
        );
        this.$apiInstance
          .sendSupportEmailTicket(sendSupportEmailTicketBody)
          .then(
            () => {
              /**
               * Component BeelseNotifications used
               */
              this.$notification.notify('SUCCESS',this.$t('TicketSent'));
              this.dialogSupportEmail = false;
              this.subjectMessage = '';
              this.bodyMessage = '';
            },
            (error) => {
              /**
               * ERROR MODIFY SUPPLIER PART
               */
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
