var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.keyComponent,
    staticClass: "module-organizationSelector text-center"
  }, [_vm.$appConfig.userSuppliers.length === 0 ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('OrganizationSelectorError')) + " ")]) : _vm._e(), _vm.$appConfig.userSuppliers.length === 1 ? _c('span', {
    staticClass: "white--text col-12 text-center font-weight-regular pt-4 body-2",
    staticStyle: {
      "letter-spacing": "0.1rem !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('OrganizationLabel')) + " " + _vm._s(_vm.$appConfig.userSuppliers[0].name) + " ")]) : [_c('v-overflow-btn', {
    staticClass: "caption form",
    attrs: {
      "solo": "",
      "dense": "",
      "prefix": "".concat(_vm.$t('OrganizationLabel')),
      "items": _vm.$appConfig.userSuppliers,
      "item-text": "name",
      "item-value": "_id",
      "value": _vm.$route.params.supplierUUID
    },
    on: {
      "change": _vm.orgChange
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }