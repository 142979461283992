<template lang="html" src="./organizationSelector.template.vue"></template>

<style lang="scss" src="./organizationSelector.scss"></style>

<script>
import { EventBus, ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./organizationSelector.i18n');

export default {
  name: 'OrganizationSelector',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      keyComponent: 0,
    };
  },
  created() {
    EventBus.$on('reloadOrganizationSelector', this.reloadSuppliersList);
  },
  methods: {
    reloadSuppliersList() {
      this.$apiInstance.getUserSuppliers(this.$appConfig.user._id).then(
        (data) => {
          this.$appConfig.userSuppliers = data;
          this.keyComponent += 1;
        },
        (error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        }
      );
    },
    orgChange(value) {
      /**
       * Emit event globalOrganizationSelectorOrganizationChanged
       */
      EventBus.$emit('globalSupplierSelectorSupplierChanged', value);
    },
  },
};
</script>
