<template>
  <v-app id="app">
    <UpdateApp></UpdateApp>
    <OfflineApp></OfflineApp>
    <BeelseDetectBrowser ref="MyBeelseDetectBrowser"></BeelseDetectBrowser>
    <BeelseNotifications ref="BCMNotification"></BeelseNotifications>
    <portal-skeleton v-if="loaded && componentToLoad === 'portalSkeleton'">
      <template v-slot:menu-header="{ miniVariant }">
        <div style="height: 120px">
          <router-link
            :to="{name: 'Home'}"
          >
            <template v-if="miniVariant">
              <v-img
                lazy-src="./assets/logo.png"
                height="85"
                width="300"
                contain
                src="./assets/logo.png"
                alt="logo"
              ></v-img>
            </template>
            <!-- <template v-else-if="new Date().getMonth() + 1 === 12">
              <v-img
                lazy-src="./assets/logo-noel.png"
                max-height="85" 
                max-width="300"
                contain
                src="./assets/logo-noel.png"
                alt="logo"
              ></v-img>
            </template> -->
            <template v-else>
              <v-img
                lazy-src="./assets/logo.png"
                max-height="85" 
                max-width="300"
                contain
                src="./assets/logo.png"
                alt="logo"
              ></v-img>
            </template>
          </router-link>
          <v-list-item-subtitle v-if="!miniVariant" class="mt-3 text-center">
            <h2 class="white--text">
              {{ $t('SupplierPortal') }}
            </h2>
            <OrganizationSelector class="my-3 d-sm-flex d-md-none"></OrganizationSelector>
          </v-list-item-subtitle>
        <!-- <v-divider class="mt-4"></v-divider> -->
        </div>
      </template>
      <template v-slot:menu-body="{ miniVariant }">
        <MenuBCM
          :divided-menus-items="dividedMenusItems"
          :logo-beelse-in-divider="0"
          :size-of-logo="miniVariant ? 30 : 50"
          :mini-variant="miniVariant"
        ></MenuBCM>
      </template>
      <template v-slot:menu-footer="{ miniVariant }">
        <v-list>
          <v-list-item v-if="miniVariant" class="justify-center">
            <v-icon
              color="orange"
              small
            >
              fas fa-file-contract
            </v-icon>
          </v-list-item>
          <v-list-item v-show="!miniVariant">
            <!-- v-show is needed here because otherwise the dialog will close automatically when miniVariant is false -->
            <GeneralTermsAndConditions></GeneralTermsAndConditions>
          </v-list-item>
        </v-list>
      </template>
      <template v-slot:top-bar>
        <v-spacer></v-spacer>
        <OrganizationSelector class="mx-3 d-none d-md-flex"></OrganizationSelector>
        <v-divider vertical class="mx-0"></v-divider>
        <DownloadProgressBtn></DownloadProgressBtn>
        <v-divider vertical class="mx-0"></v-divider>
        <HelpAndFeedback></HelpAndFeedback>
        <v-divider vertical class="mx-0"></v-divider>
        <i18n-selector></i18n-selector>
        <v-divider vertical class="mx-0"></v-divider>
        <UserMenu :user="$appConfig.user" :user-menu-items="userMenuItems"></UserMenu>
      </template>
      <template v-slot:default>
        <!-- <live-chat
          department="CMT-Supplier"
          :user="$appConfig.user"
          :language="$userLocale"
          ref="livechat"
        ></live-chat> -->
        <UserIdentitySetter></UserIdentitySetter>
        <Breadcrumbs></Breadcrumbs>
        <router-view></router-view>
      </template>
    </portal-skeleton>
    <Login
      v-else-if="loaded && componentToLoad === 'login'"
      :global-error-message="globalLoginErrorMessage"
      @login="login"
      @forgotPassword="forgotPassword"
    >
      {{ $t('SupplierPortal') }}
    </Login>
    <template v-else>
      <v-container
        fill-height
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col v-if="loaded" align="center">
            <v-row>
              <v-col>
                <v-icon
                  color="error"
                  size="100"
                >
                  fas fa-exclamation-circle
                </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h2>{{ $t('Error') }}</h2>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else align="center">
            <div class="img-background-app"></div>
            <v-row>
              <v-col>
                <SpinnerBeelse
                  :multiple="true"
                  color="orange"
                  :size="200"
                  logo="B"
                  :display-logo="false"
                >
                </SpinnerBeelse>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="text-h4 mt-12">
                  {{ $t('Loading') }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-app>
</template>

<script>
import Vue from 'vue';

import Hotjar from '@hotjar/browser';
import {
  ApiErrorParser,
  BeelseNotifications,
  BeelseDetectBrowser,
  DownloadProgressBtn,
  EventBus,
  I18nSelector,
  Login,
  // LiveChat,
  LoginInformationTracker,
  MenuBCM,
  OfflineApp,
  PortalSkeleton,
  SpinnerBeelse,
  UpdateApp,
  UserIdentitySetter,
  UserMenu
} from '@cloudmanufacturingtechnologies/portal-components';
import OrganizationSelector from './components/organizationSelector/OrganizationSelector';
import GeneralTermsAndConditions from './components/generalTermsAndConditions/GeneralTermsAndConditions';
import Breadcrumbs from './components/breabcrumbs/Breadcrumbs';
import HelpAndFeedback from './components/helpAndFeedback/HelpAndFeedback';

const i18nData = require('./app.i18n');

Vue.prototype.$appConfig = {
  user: {},
  userSuppliers: [],
  menuItems: [],
  userMenuItems: [],
  supplier: {},
  privateWarehousesArray: [],
  privateWarehousesObject: {},
  privateWarehousesUsersArray: [],
  privateWarehousesUsersObject: {}
};

export default {
  name: 'App',
  components: {
    BeelseNotifications,
    BeelseDetectBrowser,
    Breadcrumbs,
    DownloadProgressBtn,
    GeneralTermsAndConditions,
    HelpAndFeedback,
    I18nSelector,
    // LiveChat,
    Login,
    MenuBCM,
    OfflineApp,
    OrganizationSelector,
    PortalSkeleton,
    SpinnerBeelse,
    UpdateApp,
    UserIdentitySetter,
    UserMenu
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      userMenuItems: null,
      dividedMenusItems: null,
      user: this.$appConfig.user,
      loaded: false,
      componentToLoad: '',
      globalLoginErrorMessage: '',
    };
  },
  created() {
    this.$router.onReady(()=>{
      this.init();
    });

    this.$router.onError(()=>{
      this.loaded = true;
    });

    EventBus.$on('globalLoginProcessComplete', () => {
      this.checkLogin();
    });
    EventBus.$on('globalSupplierSelectorSupplierChanged', (supplierUUID) => {
      /**
       * Redirect and reload to rebuild the App
       */
      this.$router.push({name: 'Dashboard', params: { supplierUUID: supplierUUID}}).catch(err => {});
      this.loaded = false;
      this.checkLogin();
    });

    /**
     * Refresh the application if not loaded after 5sec
     * The goal is to avoid blank screen (now with spinner) on ServiceWorker post update
     */
    setTimeout(
      function() {
        if(!this.componentToLoad || this.componentToLoad.trim() === '') {
          navigator.serviceWorker.getRegistrations()
            .then((registrations) => {
              for(const registration of registrations) {
                registration.unregister();
              }
              document.location.reload();
            })
            .finally(()=>{
              this.loaded = true;
            });
        }
      }.bind(this),
      5000
    );
  },
  mounted() {
    Vue.prototype.$notification = this.$refs.BCMNotification;
  },
  methods: {
    init() {
      this.$vuetify.lang.current = this.$userLocale;
      if (process.env.NODE_ENV === 'development') {
        this.$apiInstance.apiClient.basePath = process.env.VUE_APP_API_URL;
        this.$appConfig.hotjarId = process.env.VUE_APP_HOTJAR_ID;
        this.checkLogin();
      } else if (process.env.NODE_ENV === 'production') {
        this.$http.get('env_var').then(
          (response) => {
            this.$apiInstance.apiClient.basePath = response.data.WS_URL;
            this.$appConfig.hotjarId = response.data.HOTJAR_ID;
            this.checkLogin();
          },
          (error) => {
            this.globalLoginErrorMessage = `${this.$t('BadConfig')} - status: ${
              error.status
            }`;
          }
        );
      }
    },
    deleteCookies() {
      this.$deleteCookie('_pu');
      this.$deleteCookie('_pp');
    },
    loadLogin(errorMessage = '') {
      /**
       * Delete Cookies and load login component
       */
      this.deleteCookies();
      this.globalLoginErrorMessage = errorMessage;
      this.componentToLoad = 'login';
      this.loaded = true;
    },
    login(email, password) {
      this.globalLoginErrorMessage = '';
      const authBody = new this.$BcmModel.AuthBody(email, password);
      this.$apiInstance.auth(authBody).then(
        (data) => {
          const date = new Date();
          date.setHours(date.getHours() + 8);
          const dateString = date.toGMTString();
          this.$setCookie('_pu', data._id, dateString);
          this.$setCookie('_pp', data.token, dateString);
          /**
           * Login Success
           */
          this.checkLogin();
        },
        (error) => {
          if(error?.error?.message?.includes('Request has been terminated')) {
            this.loadLogin(this.$t('loginFailedTemporary'));
          }else{
            this.loadLogin(ApiErrorParser.parseAuth(error));
          }
        }
      );
    },
    forgotPassword(email) {
      this.globalLoginErrorMessage = '';
      const emailRequestBody = new this.$BcmModel.SendNewUserPasswordBody(email);
      this.$apiInstance
        .sendNewUserPassword(emailRequestBody)
        .then(() => {
          /**
           * Forgot password Success
           */
        })
        .catch((err) => {
          this.loadLogin(ApiErrorParser.parseAuth(error));
        });
    },
    checkLogin() {
      const userUUID = this.$getCookie('_pu');
      const userPassword = this.$getCookie('_pp');
      if (!userUUID || !userPassword) {
        this.loadLogin();
      } else {
        /**
         * Cookies found, verify Authentication
         */
        this.$apiInstance.apiClient.authentications[
          'JWTAuth'
        ].accessToken = userPassword;
        this.$apiInstance.getUser(userUUID).then(
          (data) => {
            this.$appConfig.user = data;
            this.getUserSuppliers();
            this.getBackendTranslations();
          },
          (error) => {
            this.loadLogin(ApiErrorParser.parseAuth(error));
          }
        );
      }
    },
    getSupplier() {
      this.$apiInstance.getSupplier(
        this.$route.params.supplierUUID
      ).then(data => {
        this.$appConfig.supplier = data;
      });
    },
    getUserSuppliers() {
      this.$apiInstance.getUserSuppliers(this.$appConfig.user._id).then(
        (data) => {
          this.$appConfig.userSuppliers = data;
          /**
           * Check and add login global error message
           * if the user doesn't have access to this portal
           */
          if (this.$appConfig.userSuppliers.length === 0) {
            this.loadLogin('NoAccess');
          } else {
            /**
             * If no supplierUUID already exist in URL params,
             * select the first one.
             * If supplierUUID exist in the URL params,
             * check if the current user have access to this supplier
             */
            if (!this.$route.params.supplierUUID) {
              this.$route.params.supplierUUID = this.$appConfig.userSuppliers[0]._id;
            }
            /**
             * Check if the current supplierUUID param is valid and exist in the user suppliers list
             */
            let userSupplierFound = false;
            this.$appConfig.userSuppliers.forEach((supplier) => {
              if (!userSupplierFound && supplier._id === this.$route.params.supplierUUID) {
                userSupplierFound = true;
              }
            });
            if (!userSupplierFound) {
              /**
                 * Reload the App
                 */
              this.reload();
            }else{
              this.getSupplier();
              /**
               * INITIALISATION HOT JAR
               */
              Hotjar.init(this.$appConfig.hotjarId, 6);
              /**
               * HOT JAR information
               */
              Hotjar.identify(this.$appConfig.user._id, {
                first_name: this.$appConfig.user.firstname,
                signed_up_at: this.$appConfig.user.created,
                supplier_id: this.$route.params.supplierUUID,
                lang: this.$userLocale,
              });
              this.loadPortalSkeleton();
            }
            this.getSupplierPrivateWarehouses();
            this.getSupplierPrivateWarehousesUsers();
          }
        },
        (error) => {
          this.loadLogin('NoAccess');
        }
      );
    },
    loadPortalSkeleton() {
      this.dividedMenusItems = [
        [
          {
            name: this.$t('menuItemsDashboard'),
            iconClass: 'far fa-calendar-check',
            fullPath: {name: 'Dashboard', params: { supplierUUID: `${this.$route.params.supplierUUID}`}},
        
          },
        ],
        [
          {
            name: this.$t('menuItemsCustomers'),
            iconClass: 'fas fa-address-book',
            fontSize: '13',
            fullPath: {name: 'Customers', params: { supplierUUID: `${this.$route.params.supplierUUID}`}},
          },
        ],
        [
          {
            name: this.$t('menuItemsQuotes'),
            iconClass: 'fas fa-file-signature',
            fontSize: '13',
            fullPath: {name: 'Quotes', params: { supplierUUID: `${this.$route.params.supplierUUID}`}},
          },
          {
            name: this.$t('menuItemsOrders'),
            iconClass: 'fas fa-box-open',
            fontSize: '13',
            fullPath: {name: 'Orders', params: { supplierUUID: `${this.$route.params.supplierUUID}`}},
          },
          {
            name: this.$t('menuPriceEstimation'),
            iconClass: 'fas fa-euro-sign',
            fontSize: '18',
            fullPath: {name: 'PriceEstimation', params: { supplierUUID: `${this.$route.params.supplierUUID}`}},
          },
        ],
        [
          {
            name: this.$t('menuInternalWarehouse'),
            iconClass: 'fas fa-warehouse',
            fontSize: '13',
            fullPath: {name: 'InternalWarehouse', params: { supplierUUID: `${this.$route.params.supplierUUID}`}},
          },
          {
            name: this.$t('menuProduction'),
            iconClass: 'fas fa-industry',
            fullPath: {name: 'Production', params: { supplierUUID: `${this.$route.params.supplierUUID}`}},
          },
        ],
        [
          {
            name: this.$t('menuItemsAdmin'),
            iconClass: 'fas fa-users-cog',
            fullPath: {name: 'Administration', params: { supplierUUID: `${this.$route.params.supplierUUID}`}},
          },
        ],
      ];
      this.userMenuItems = [
        {
          name: this.$t('UserMenuMyAccount'),
          icon: 'fas fa-cogs',
          route: {name: 'UserAccount'},
        },
        {
          name: this.$t('UserMenuLogOut'),
          icon: 'fas fa-sign-out-alt',
          route: {name: 'Logout'},
        },
      ];
      /**
       * Load the component to load the vue router
       */
      this.componentToLoad = 'portalSkeleton';
      this.loaded = true;

      LoginInformationTracker.push(
        this.$apiInstance,
        this.$BcmModel,
        this.$appConfig.user,
        'supplier'
      );
    },
    reload() {
      /**
       * Reload the App
       * In this case we should use location.href due to bad params in the router.
       * This will prevent infinite reload due to invalid access to a warehouse UUID
       * 
       * issue 1190
       */
      location.href = '/';
      this.getUserSuppliers();
    },
    getBackendTranslations() {
      this.$apiInstance.getAllTechnologiesAndMaterialsCompatibilities().then(data => {
        for(const technologyObj of data) {
          for(const materialObj of technologyObj.materials) {
            for(const language of Object.keys(materialObj.name)) {
              const material = materialObj.material;
              const translation = {};
              translation[material] = materialObj.name[language];
              this.$root.$options.i18n.mergeLocaleMessage(language, translation);
            }
          }
        }
      });
    },
    getSupplierPrivateWarehouses() {
      this.$apiInstance.getSupplierPrivateWarehouses(
        this.$route.params.supplierUUID
      ).then(data => {
        this.$appConfig.privateWarehousesArray = data;
        /**
         * For easier access we use an object with ID of the warehouse as key
         */
        for(const privateWarehouse of this.$appConfig.privateWarehousesArray) {
          this.$appConfig.privateWarehousesObject[privateWarehouse._id] = privateWarehouse;
        }
      });
    },
    getSupplierPrivateWarehousesUsers() {
      this.$apiInstance.getSupplierPrivateWarehousesUsers(
        this.$route.params.supplierUUID
      ).then(data => {
        this.$appConfig.privateWarehousesUsersArray = data;
        /**
         * For easier access we use an object with ID of the user as key
         */
        for(const privateWarehouseUser of this.$appConfig.privateWarehousesUsersArray) {
          this.$appConfig.privateWarehousesUsersObject[privateWarehouseUser._id] = privateWarehouseUser;
        }
      });
    }
  },
};
</script>
