import Vue from 'vue';
import Router from 'vue-router';
import { Page404 } from '@cloudmanufacturingtechnologies/portal-components';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "Home" */ './pages/index/PageIndex.vue'),
    },
    {
      path: '/:supplierUUID/dashboard',
      name: 'Dashboard',
      component: () => import(/* webpackChunkName: "Dashboard" */ './pages/dashboard/PageDashboard.vue'),
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import(/* webpackChunkName: "Logout" */ './pages/logout/PageLogout.vue'),
    },
    {
      path: '/:supplierUUID/administration',
      name: 'Administration',
      component: () => import(/* webpackChunkName: "Administration" */ './pages/administration/PageAdministration.vue'),
      props: true,
      children: [
        {
          path: 'general',
          name: 'AdministrationGeneral',
          component: () => import(/* webpackChunkName: "AdministrationGeneral" */ './pages/administration/general/PageAdministrationGeneral.vue'),
        },
        {
          path: 'payment',
          name: 'AdministrationPayment',
          component: () => import(/* webpackChunkName: "AdministrationPayment" */ './pages/administration/payment/PageAdministrationPayment'),
        },
        {
          path: 'security',
          name: 'AdministrationUserAndSecurity',
          component: () => import(/* webpackChunkName: "AdministrationUserAndSecurity" */ './pages/administration/userAndSecurity/PageAdministrationUserAndSecurity.vue'),
        },
        {
          path: 'printers',
          name: 'AdministrationPrinters',
          component: () => import(/* webpackChunkName: "AdministrationPrinters" */ './pages/administration/printers/PageAdministrationPrinters.vue'),
        },
      ],
    },
    {
      path: '/user/account',
      name: 'UserAccount',
      component: () => import(/* webpackChunkName: "UserAccount" */ './pages/userAccount/PageUserAccount.vue'),
    },
    {
      path: '/:supplierUUID/part/:partUUID',
      name: 'Part',
      component: () => import(/* webpackChunkName: "Part" */ './pages/part/PagePart.vue'),
    },
    // {
    //   path: '/:supplierUUID/bcm/homologations',
    //   name: 'PartsToValidate',
    //   component: () => import(/* webpackChunkName: "PartsToValidate" */ './pages/priceRequests/PagePriceRequests.vue'),
    // },
    {
      path: '/:supplierUUID/customers',
      name: 'Customers',
      component: () => import(/* webpackChunkName: "Customers" */ './pages/customers/PageCustomers.vue'),
    },
    {
      path: '/:supplierUUID/customer/:customerUUID',
      name: 'Customer',
      component: () => import(/* webpackChunkName: "Customer" */ './pages/customer/PageCustomer.vue'),
      props: true,
      children: [
        {
          path: 'information',
          name: 'CustomerInformation',
          component: () => import(/* webpackChunkName: "CustomerInformation" */ './pages/customer/information/PageCustomerInformation.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Customers',
                link: '#/:supplierUUID/customers',
              },
              {
                name: 'Customer',
              },
            ],
            parent: ['Customers'],
          },
        },
        {
          path: 'contacts',
          name: 'CustomerContacts',
          component: () => import(/* webpackChunkName: "CustomerContacts" */ './pages/customer/contacts/PageCustomerContacts.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Customers',
                link: '#/:supplierUUID/customers',
              },
              {
                name: 'Customer',
              },
            ],
            parent: ['Customers'],
          },
        },
        {
          path: 'quotes',
          name: 'CustomerQuotes',
          component: () => import(/* webpackChunkName: "CustomerQuotes" */ './pages/customer/quotes/PageCustomerQuotes.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Customers',
                link: '#/:supplierUUID/customers',
              },
              {
                name: 'Customer',
              },
            ],
            parent: ['Customers'],
          },
        },
        {
          path: 'orders',
          name: 'CustomerOrders',
          component: () => import(/* webpackChunkName: "CustomerOrders" */ './pages/customer/orders/PageCustomerOrders.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Customers',
                link: '#/:supplierUUID/customers',
              },
              {
                name: 'Customer',
              },
            ],
            parent: ['Customers'],
          },
        },
        {
          path: 'parts',
          name: 'CustomerParts',
          component: () => import(/* webpackChunkName: "CustomerParts" */ './pages/customer/parts/PageCustomerParts.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Customers',
                link: '#/:supplierUUID/customers',
              },
              {
                name: 'Customer',
              },
            ],
            parent: ['Customers'],
          },
        },
      ]
    },
    {
      path: '/:supplierUUID/customer/:customerUUID/part/:partUUID',
      name: 'CustomerPart',
      component: () => import(/* webpackChunkName: "BcmPart" */ './pages/bcmPart/PageBcmPart.vue'),
      children: [
        {
          path: 'overview',
          name: 'BcmPartOverview',
          component: () => import(/* webpackChunkName: "BcmPartOverview" */ './pages/bcmPart/overview/PageBcmPartOverview.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Customers',
                link: '#/:supplierUUID/customers',
              },
              {
                name: 'Customer',
                link: '#/:supplierUUID/customer/:customerUUID/information'
              },
              {
                name: 'Parts',
                link: '#/:supplierUUID/customer/:customerUUID/parts'
              },
              {
                name: 'Part',
              },
            ],
          },
        },
        {
          path: 'order',
          name: 'BcmPartOrder',
          component: () => import(/* webpackChunkName: "BcmPartOrder" */ './pages/bcmPart/order/PageBcmPartOrder.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Customers',
                link: '#/:supplierUUID/customers',
              },
              {
                name: 'Customer',
                link: '#/:supplierUUID/customer/:customerUUID/information'
              },
              {
                name: 'Parts',
                link: '#/:supplierUUID/customer/:customerUUID/parts'
              },
              {
                name: 'Part',
              },
            ],
          },
        },
        {
          path: 'production',
          name: 'BcmPartProduction',
          component: () => import(/* webpackChunkName: "BcmPartProduction" */ './pages/bcmPart/production/PageBcmPartProduction.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Customers',
                link: '#/:supplierUUID/customers',
              },
              {
                name: 'Customer',
                link: '#/:supplierUUID/customer/:customerUUID/information'
              },
              {
                name: 'Parts',
                link: '#/:supplierUUID/customer/:customerUUID/parts'
              },
              {
                name: 'Part',
              },
            ],
          },
        },
        {
          path: 'attachments',
          name: 'BcmPartAttachments',
          component: () => import(/* webpackChunkName: "BcmPartAttachments" */ './pages/bcmPart/attachments/PageBcmPartAttachments.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Customers',
                link: '#/:supplierUUID/customers',
              },
              {
                name: 'Customer',
                link: '#/:supplierUUID/customer/:customerUUID/information'
              },
              {
                name: 'Parts',
                link: '#/:supplierUUID/customer/:customerUUID/parts'
              },
              {
                name: 'Part',
              },
            ],
          },
        },
      ],
    },
    // {
    //   path: '/:supplierUUID/part/:partUUID',
    //   name: 'BcmPart',
    //   component: () => import(/* webpackChunkName: "BcmPart" */ './pages/bcmPart/PageBcmPart.vue'),
    //   props: true,
    //   children: [
    //     {
    //       path: 'overview',
    //       name: 'BcmPartOverview',
    //       component: () => import(/* webpackChunkName: "BcmPartOverview" */ './pages/bcmPart/overview/PageBcmPartOverview.vue'),
    //       meta: {
    //         breadcrumb: [
    //           {
    //             name: 'Customers',
    //             link: '#/:supplierUUID/customers',
    //           },
    //           {
    //             name: 'Customer',
    //           },
    //         ],
    //       },
    //     },
    //     {
    //       path: 'order',
    //       name: 'BcmPartOrder',
    //       component: () => import(/* webpackChunkName: "BcmPartOrder" */ './pages/bcmPart/order/PageBcmPartOrder.vue'),
    //       meta: {
    //         breadcrumb: [
    //           {
    //             name: 'BcmLibrary',
    //             link: '#/:supplierUUID/bcm/library',
    //           },
    //           {
    //             name: 'BcmPart',
    //           },
    //         ],
    //         parent: ['Order'],
    //       },
    //     },
    //     {
    //       path: 'production',
    //       name: 'BcmPartProduction',
    //       component: () => import(/* webpackChunkName: "BcmPartProduction" */ './pages/bcmPart/production/PageBcmPartProduction.vue'),
    //       meta: {
    //         breadcrumb: [
    //           {
    //             name: 'BcmLibrary',
    //             link: '#/:supplierUUID/bcm/library',
    //           },
    //           {
    //             name: 'BcmPart',
    //           },
    //         ],
    //         parent: ['Order'],
    //       },
    //     },
    //     {
    //       path: 'attachments',
    //       name: 'BcmPartAttachments',
    //       component: () => import(/* webpackChunkName: "BcmPartAttachments" */ './pages/bcmPart/attachments/PageBcmPartAttachments.vue'),
    //       meta: {
    //         breadcrumb: [
    //           {
    //             name: 'BcmLibrary',
    //             link: '#/:supplierUUID/bcm/library',
    //           },
    //           {
    //             name: 'BcmPart',
    //           },
    //         ],
    //         parent: ['Order'],
    //       },
    //     },
    //   ],
    // },
    {
      path: '/:supplierUUID/internal/warehouse',
      name: 'InternalWarehouse',
      component: () => import(/* webpackChunkName: "InternalWarehouse" */ './pages/internalWarehouseParts/PageInternalWarehouseParts.vue'),
    },
    {
      path: '/:supplierUUID/internal/warehouse/:supplierPartUUID',
      name: 'InternalWarehousePart',
      component: () => import(/* webpackChunkName: "InternalWarehousePart" */ './pages/internalWarehousePart/PageInternalWarehousePart.vue'),
      props: true,
      children: [
        {
          path: 'overview',
          name: 'InternalWarehousePartOverview',
          component: () => import(/* webpackChunkName: "InternalWarehousePartOverview" */ './pages/internalWarehousePart/overview/PageInternalWarehousePartOverview.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'InternalWarehouse',
                link: '#/:supplierUUID/internal/warehouse',
              },
              {
                name: 'Part',
              },
            ],
          },
        },
        {
          path: 'production',
          name: 'InternalWarehousePartProduction',
          component: () => import(/* webpackChunkName: "InternalWarehousePartProduction" */ './pages/internalWarehousePart/production/PageInternalWarehousePartProduction.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'InternalWarehouse',
                link: '#/:supplierUUID/internal/warehouse',
              },
              {
                name: 'Part',
              },
            ],
          },
        },
      ],
    },
    {
      path: '/:supplierUUID/production/',
      name: 'Production',
      component: () => import(/* webpackChunkName: "Production" */ './pages/production/PageProduction.vue'),
    },
    {
      path: '/:supplierUUID/price/estimation/',
      name: 'PriceEstimation',
      component: () => import(/* webpackChunkName: "PriceEstimation" */ './pages/priceEstimation/PagePriceEstimation.vue'),
    },
    {
      path:
        '/:supplierUUID/production/printer/brand/:printerBrand/printer/model/:printerModel/',
      name: 'PrinterProduction',
      component: () => import(/* webpackChunkName: "PrinterProduction" */ './components/printerProduction/PagePrinterProduction.vue'),
    },
    {
      path: '/:supplierUUID/quote/',
      name: 'Quotes',
      component: () => import(/* webpackChunkName: "Quotes" */ './pages/quotes/PageQuotes.vue'),
      meta: {
        breadcrumb: [],
        parent: ['Dashboard'],
      },
    },
    {
      path: '/:supplierUUID/quote/:quoteUUID',
      name: 'Quote',
      component: () => import(/* webpackChunkName: "Quote" */ './pages/quote/PageQuote.vue'),
      meta: {
        breadcrumb: [
          {
            name: 'Quotes',
            link: '#/:supplierUUID/quote/',
          },
          {
            name: 'Quote',
          },
        ],
        parent: ['Dashboard'],
      },
    },
    {
      path: '/:supplierUUID/order/',
      name: 'Orders',
      component: () => import(/* webpackChunkName: "Orders" */ './pages/orders/PageOrders.vue'),
      meta: {
        breadcrumb: [],
        parent: ['Dashboard'],
      },
    },
    {
      path: '/:supplierUUID/order/:orderUUID',
      name: 'Order',
      component: () => import(/* webpackChunkName: "Order" */ './pages/order/PageOrder.vue'),
      meta: {
        breadcrumb: [
          {
            name: 'Orders',
            link: '#/:supplierUUID/order/',
          },
          {
            name: 'Order',
          },
        ],
        parent: ['Dashboard'],
      },
    },
    {
      path: '/:supplierUUID/user/:userUUID',
      name: 'User',
      component: () => import(/* webpackChunkName: "User" */ './pages/user/PageUser.vue'),
      meta: {
        breadcrumb: [
          {
            name: 'Administration',
            link: '#/:supplierUUID/administration/',
          },
          {
            name: 'User',
          },
        ],
      },
    },
    {
      path: '*',
      name: '404',
      component: Page404,
    },
  ],
});
