import Vue from 'vue';
import VueResource from 'vue-resource';
import BcmSupplierJavascriptEs6 from '@cloudmanufacturingtechnologies/bcm-supplier-javascript-es6';
import App from './App.vue';
import router from './router';
import { ServiceWorker, GlobalTranslateMessages, VuetifyBCM as vuetify } from '@cloudmanufacturingtechnologies/portal-components';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import VueI18n from 'vue-i18n';

ServiceWorker();

/**
 * Detect scrolling changes and reset
 */

document.onscroll = function() {
  setTimeout(function() {
    if (window.scrollY !== 0 || window.scrollX !== 0) {
      window.scrollTo(0, 0);
    }
  }, 500);
};

/**
 * Bugsnag
 * 
 * Activate bugsnag for compiled development platform, test platform and prod platform
 * Do not use in full development
 */

let releaseStage = null;
if(/^172.10.0.1[0-9]$/.test(window.location.hostname)) {
  releaseStage = 'development';
}else if(/^[A-Za-z]+.test.cm-technology.io$/.test(window.location.hostname)) {
  releaseStage = 'test';
}else if(/^[A-Za-z]+.cm-technology.io$/.test(window.location.hostname)) {
  releaseStage = 'production';
}
if(releaseStage) {
  Bugsnag.start({
    apiKey: '47ec08bb32594b39b96fd9d814f57440',
    plugins: [new BugsnagPluginVue()],
    releaseStage,
    metadata: {
      portal: 'supplier'
    }
  });
}

Vue.use(VueResource);
Vue.use(VueI18n);

Vue.config.productionTip = false;

const tldjs = require('tldjs');

/**
 * Define GLOBAL VARIABLES
 */

Vue.prototype.$BcmModel = BcmSupplierJavascriptEs6;
Vue.prototype.$apiInstance = new BcmSupplierJavascriptEs6.DefaultApi();
if(Vue.prototype.$apiInstance.apiClient && Vue.prototype.$apiInstance.apiClient.defaultHeaders) {
  Vue.prototype.$apiInstance.apiClient.defaultHeaders = {};
}

Vue.use(VueResource);

/**
 * Define GLOBAL VARIABLES
 */

/**
 * Get Cookie
 */

Vue.prototype.$getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  let result = null;
  if (parts.length === 2) {
    result = parts.pop().split(';').shift();
  }
  return result;
};

/**
 * Set Cookie
 */

Vue.prototype.$setCookie = (name, value, dateString) => {
  let secure = '';
  let domain = window.location.hostname;
  if (window.location.protocol === 'https:') {
    secure = '; secure';
  }
  const regexIp = new RegExp(
    '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
  );
  if (!regexIp.test(domain)) {
    if (tldjs.getDomain(domain) !== domain) {
      domain = tldjs.getDomain(domain);
    }
  }
  document.cookie = `${name}=${value}; expires=${dateString}; path=/; domain=${domain} ${secure}`;
};

/**
 * Delete Cookie
 */

Vue.prototype.$deleteCookie = (name) => {
  const date = new Date();
  date.setHours(date.getHours() - 8);
  const dateString = date.toGMTString();
  Vue.prototype.$setCookie(name, '', dateString);
};

/**
 * Beelse API Init
 * @type {BeelseApi.DefaultApi}
 */

// Vue.prototype.$apiInstance = new this.$BcmModel.DefaultApi();

/**
 * Default language for i18n
 * @type {string}
 */

let languageFound = null;

switch (Vue.prototype.$getCookie('language')) {
case 'en':
  Vue.prototype.$userLocale = 'en';
  break;
case 'fr':
  Vue.prototype.$userLocale = 'fr';
  break;
case 'de':
  Vue.prototype.$userLocale = 'de';
  break;
default:
  /**
   * detect browser locale
   */
  if (window.navigator?.languages) {
    window.navigator.languages.forEach((lang) => {
      if (!languageFound) {
        switch (lang.toLowerCase()) {
        case 'en':
          languageFound = 'en';
          break;
        case 'fr':
          languageFound = 'fr';
          break;
        case 'de':
          languageFound = 'de';
          break;
        }
      }
    });
  }
  if (!languageFound) {
    languageFound = 'en';
  }
  Vue.prototype.$userLocale = languageFound;
  break;
}

/**
 * Global translation
 */

const i18n = new VueI18n({
  locale: Vue.prototype.$userLocale,
  messages: GlobalTranslateMessages,
  dateTimeFormats: GlobalTranslateMessages,
  numberFormats: GlobalTranslateMessages,
  silentFallbackWarn: true,
});

/**
 * Load App
 */

new Vue({
  name: 'MainApp',
  i18n,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
